import { Api } from '@/core/api.js'
const api = new Api()
import Vue from 'vue'

const state = {
    wrongTickets: {},
    wrongTicketsFilters: {},
    wrongTicketsFiltersActive: {
        date: 'month',
        id_sites: 'filters.all_restaurants',
    },
    pageCount: 0,
    totalRows: 0,
}

const getters = {
    getWrongTickets: (state) => (id) => {
        if (id) {
            return state.wrongTickets[id]
        }
        return state.wrongTickets
    },
    getPageCount: (state) => {
        return state.pageCount
    },
    getTotalRows: (state) => {
        return state.totalRows
    },
    getWrongTicketsFilters: (state) => {
        return state.wrongTicketsFilters
    },
    getWrongTicketsFiltersActive: (state) => {
        return state.wrongTicketsFiltersActive
    }
}

const actions = {
    loadWrongTickets (context, params) {
        return api
            .post(`ticket/list`, params)
            .then((response) => {
                if (response.status) {
                    context.commit('setWrongTickets', response.data.tickets)
                    context.commit('setPageCount', response.data.total_pages)
                    context.commit('setTotalRows', response.data.total_rows)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    revalidateTicket (context, params) {
        return api
            .post(`ticket/resend`, params)
            .then((response) => {
                if (response.status) {
                    return response
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    downloadPDF (context, params) {
        return api
            .post(`admin/bill/list/download/pdf`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    downloadEXCEL (context, params) {
        return api
            .post(`ticket/list/download/excel`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    downloadXML (context, params) {
        return api
            .post(`admin/bill/list/download/xml`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    loadFiltersWrongTickets (context) {
        return api
            .get(`ticket/filters`)
            .then((response) => {
                if (response.status) {
                    context.commit('setWrongTicketsFilters', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    }
}

const mutations = {
    setWrongTickets: (state, value) => {
        state.wrongTickets = value
        Vue.set(state, 'wrongTickets', value)
    },
    setPageCount: (state, value) => {
        state.pageCount = value
        Vue.set(state, 'pageCount', value)
    },
    setTotalRows: (state, value) => {
        state.totalRows = value
        Vue.set(state, 'totalRows', value)
    },
    setWrongTicketsFilters: (state, value) => {
        state.wrongTicketsFilters = value
        Vue.set(state, 'wrongTicketsFilters', value)
    },
    setWrongTicketsFiltersActive: (state, value) => {
        state.wrongTicketsFiltersActive = value
        Vue.set(state, 'wrongTicketsFiltersActive', value)
    },
    deleteTicket: (state, value) => {
        var wrongTickets = state.wrongTickets
        delete wrongTickets[value]
        Vue.set(state, 'wrongTickets', wrongTickets)
    },
    setErrorType: (state, value) => {
        var wrongTickets = state.wrongTickets
        wrongTickets[value.id].error = value.error.msg
        Vue.set(state, 'wrongTickets', wrongTickets)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
