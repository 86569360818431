import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Api } from '@/core/api.js'

Vue.use(VueI18n)

const api = new Api()

const defaultLocale = 'es'

const languages = {}

const i18n = new VueI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages: Object.assign(languages)
})

const loadedLanguages = ['es']

i18n.loadLanguageAsync = function (lang) {
    return api.language(lang).then((response) => {
        i18n.setLocaleMessage(lang, response)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
    })
}

i18n.loadLanguageAsync(defaultLocale)

export default i18n

function setI18nLanguage(lang) {
    i18n.locale = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}
