<template>
    <div id="download-layout">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'Download-Layout'
}
</script>

<style lang="scss"></style>
