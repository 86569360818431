import { Api } from '@/core/api.js'
const api = new Api()
import Vue from 'vue'

const state = {
    restaurants: {}
}

const getters = {
    getRestaurants: (state) => (id) => {
        if (id) {
            return state.restaurants[id]
        }
        return state.restaurants
    }
}

const actions = {
    loadRestaurants (context) {
        return api
            .get(`restaurant/list`)
            .then((response) => {
                if (response.status) {
                    context.commit('setRestaurants', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    edit (context, params) {
        return api
            .post(`restaurant/edit`, { id: params.id, alternative_address: params.alternative_address })
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            })
    }
}

const mutations = {
    setRestaurants: (state, value) => {
        state.restaurants = value
        Vue.set(state, 'restaurants', value)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
