<template>
    <div v-if="show" class="snackbar-container" :class="{ showPopup: showInPopup }">
        <transition name="fade">
            <div class="snackbar" :class="[type, customClass]">
                <span class="text" v-html="message"> </span>
                <span v-if="closeable" class="close-snackbar" @click="closeUndo()"></span>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            talk: false,
            closeable: true,
            type: '',
            message: '',
            customClass: '',
            showInPopup: false,
            action: function () {},
            duration: 3000000
        }
    },
    computed: {},
    methods: {
        doIt() {
            this.btn.callback(this.btn.props)
        },
        closeUndo() {
            this.show = false
        }
    },
    mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// snackbar
.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s ease-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
}

.snackbar-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 80px;
    left: 0;
    pointer-events: none;
    z-index: 3;

    .snackbar {
        background-color: $yellow;
        display: flex;
        align-content: center;
        align-items: center;
        border-radius: 4px;
        min-height: 0px;
        height: auto;
        max-width: 900px;
        width: auto;
        padding: 9px 12px;
        pointer-events: auto;

        .text {
            font-size: 20px;
            font-family: Speedee;
            color: #adadad;
        }
        .close-snackbar {
            @extend .interaction;
            border-radius: 50%;
            // @include background($color: $main-t70, $image: img('close_neutro_s90.svg'), $size: 12px);
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0px 0px 0px 12px;
        }

        &.success {
            background-color: $green2;

            .text {
                color: black;
            }
            .close-snackbar {
                background-image: url('../../../public/img/cancel.svg');
                // @include bgHover($done-t30);
                background-size: 20px;
            }
        }

        // &.warning {
        //     background-color: $warning-t50;

        //     .text {
        //         color: $warning-s70;
        //     }
        //     .close-snackbar {
        //         background-color: $warning-t30;
        //         @include bgHover($warning-t30);
        //     }
        // }

        &.error {
            background-color: $red;

            .text {
                color: black;
            }
            .close-snackbar {
                background-image: url('../../../public/img/cancel.svg');
                background-size: 20px;
            }
        }
    }
}
</style>
