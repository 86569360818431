import { Api } from '@/core/api.js'
const api = new Api()
import Vue from 'vue'

const state = {
    validTickets: {},
    validTicketsFilters: {},
    validTicketsFiltersActive: {
        date: 'month',
        id_sites: 'filters.all_restaurants',
    },
    pageCount: 0,
    totalRows: 0,
}

const getters = {
    getValidTickets: (state) => (id) => {
        if (id) {
            return state.validTickets[id]
        }
        return state.validTickets
    },
    getPageCount: (state) => {
        return state.pageCount
    },
    getTotalRows: (state) => {
        return state.totalRows
    },
    getValidTicketsFilters: (state) => {
        return state.validTicketsFilters
    },
    getValidTicketsFiltersActive: (state) => {
        return state.validTicketsFiltersActive
    }
}

const actions = {
    loadValidTickets (context, params) {
        return api
            .post(`ticket/list`, params)
            .then((response) => {
                if (response.status) {
                    context.commit('setValidTickets', response.data.tickets)
                    context.commit('setPageCount', response.data.total_pages)
                    context.commit('setTotalRows', response.data.total_rows)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    downloadPDF (context, params) {
        return api
            .post(`admin/bill/list/download/pdf`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    downloadEXCEL (context, params) {
        return api
            .post(`ticket/list/download/excel`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    downloadXML (context, params) {
        return api
            .post(`admin/bill/list/download/xml`, params)
            .then((response) => {
                if (response.status) {
                    return response.data
                } else {
                    return response
                }
            })
            .catch((error) => {
                return error
            })
    },
    loadFiltersValidTickets (context) {
        return api
            .get(`ticket/filters`)
            .then((response) => {
                if (response.status) {
                    context.commit('setValidTicketsFilters', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    }
}

const mutations = {
    setValidTickets: (state, value) => {
        state.validTickets = value
        Vue.set(state, 'validTickets', value)
    },
    addTicket: (state, value) => {
        var tickets = { ...state.validTickets }
        tickets[value.id] = value
        Vue.set(state, 'validTickets', tickets)
    },
    setPageCount: (state, value) => {
        state.pageCount = value
        Vue.set(state, 'pageCount', value)
    },
    setTotalRows: (state, value) => {
        state.totalRows = value
        Vue.set(state, 'totalRows', value)
    },
    setValidTicketsFilters: (state, value) => {
        state.validTicketsFilters = value
        Vue.set(state, 'validTicketsFilters', value)
    },
    setValidTicketsFiltersActive: (state, value) => {
        state.validTicketsFiltersActive = value
        Vue.set(state, 'validTicketsFiltersActive', value)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
