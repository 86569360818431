import Template from './overlay.vue'

const getDefaultState = () => {
    return {
        show: false
    }
}

const globalOptions = getDefaultState()

let instance

const Init = function (config = {}) {
    const Tpl = this.extend(Template)

    if (!instance) {
        instance = new Tpl()
    }

    config = {
        ...globalOptions,
        ...instance.$data,
        ...config
    }

    for (let key in config) {
        if (Object.prototype.hasOwnProperty.call(config, key)) {
            instance.$data[key] = config[key]
        }
    }
}

const Show = function (config = {}) {
    Init.call(this, config)
    instance.$data.show = true
    document.body.style.overflow = 'hidden'
    document.body.appendChild(instance.$mount().$el)
}

const Hide = function () {
    if (typeof instance != 'undefined') {
        Object.assign(instance.$data, getDefaultState())
        document.body.style.overflow = 'auto'
    }
}

export default {
    install(Vue) {
        Vue.prototype.$overlay = Init.bind(Vue)
        Vue.prototype.$overlay.show = Show.bind(Vue)
        Vue.prototype.$overlay.hide = Hide.bind(Vue)
    }
}
