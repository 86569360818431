<template>
    <div name="modal" v-if="show">
        <div id="loading"></div>
        <div id="overlay"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false
        }
    },
    methods: {}
}
</script>

<style lang="scss">
#overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    &.blocking {
        z-index: 2;
    }
}

#loading {
    background-image: url('../../../public/img/loader.svg');
    background-size: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}
</style>
