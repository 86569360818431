import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL

export class Api {
    constructor() {}

    get(uri) {
        const url = `${API_URL}${uri}`
        axios.defaults.headers.common['Authorization'] = localStorage.token
        return axios
            .get(url, {})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                return error
            })
    }

    post(uri, params) {
        const url = `${API_URL}${uri}`
        axios.defaults.headers.common['Authorization'] = localStorage.token
        const oParams = new URLSearchParams()
        Object.keys(params).forEach((key) => {
            oParams.append(key, params[key])
        })

        if (uri != 'admin/login') {
            // ADD BY DEFAULT TIMEOUT 10sec
            axios.defaults.timeout = 10000
        }

        return axios
            .post(url, oParams)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                return error
            })
    }

    language(lang) {
        const url = `${API_URL}lang/${lang}`
        return axios
            .get(url, {
                validateStatus: function (status) {
                    return status
                }
            })
            .then(function (response) {
                return response.data
            })
    }
}
