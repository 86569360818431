import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const DownloadTicket = () => import('../views/DownloadTicket.vue')
const Login = () => import('../views/Login')
const ValidTickets = () => import('../views/ValidTickets')
const WrongTickets = () => import('../views/WrongTickets')
const Restaurants = () => import('../views/Restaurants')
const Notifications = () => import('../views/Notifications')
const Access = () => import('../views/Access')
const RestaurantEdit = () => import('../views/RestaurantEdit')

const routes = [
    {
        path: '/',
        name: 'ValidTickets',
        meta: { navName: 'tools.valid_tickets', layout: 'admin-layout' },
        component: ValidTickets,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/valid-tickets/:id',
        name: 'DownloadTicket',
        meta: { layout: 'download-layout' },
        component: DownloadTicket
    },
    {
        path: '/wrong-tickets',
        meta: { navName: 'tools.wrong_tickets', layout: 'admin-layout' },
        name: 'WrongTickets',
        component: WrongTickets,
        beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/restaurants',
        meta: { navName: 'restaurants.title', layout: 'admin-layout' },
        name: 'Restaurants',
        component: Restaurants
        // beforeEnter: checkBeforeEnter
    },
    {
        path: '/admin/restaurants/:id/edit',
        meta: { navName: 'restaurants.title_edit', layout: 'admin-layout' },
        name: 'RestaurantEdit',
        component: RestaurantEdit
        // beforeEnter: checkBeforeEnter
    },
    {
        path: '/notifications',
        meta: {
            navName: 'notification.title',
            layout: 'admin-layout'
        },
        name: 'Notifications',
        component: Notifications
        // beforeEnter: checkBeforeEnter
    },
    {
        path: '/access',
        meta: {
            navName: 'users.title',
            layout: 'admin-layout'
        },
        name: 'Access',
        component: Access
        // beforeEnter: checkBeforeEnter
    },
    {
        path: '/access/add',
        meta: {
            navName: 'users.title',
            layout: 'admin-layout'
        },
        name: 'AddAccess',
        component: Access
        // beforeEnter: checkBeforeEnter
    },
    {
        path: '/access/:id',
        meta: {
            navName: 'users.title',
            layout: 'admin-layout'
        },
        name: 'EditAccess',
        component: Access
        // beforeEnter: checkBeforeEnter
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            layout: 'download-layout'
        },
        component: Login
    },
    {
        path: '/admin/login/resetPassword/:id',
        name: 'ResetPassword',
        meta: {
            layout: 'download-layout'
        },
        component: Login
    },
    {
        path: '/miturnologin/:id1/:id2',
        name: 'MiTurnoLogin',
        meta: {
            layout: 'download-layout'
        },
        component: Login
    }
]

async function checkBeforeEnter (to, from, next) {
    store
        .dispatch('validateToken')
        .then(() => {
            store.getters['isLogged'] ? next() : next('/login')
        })
        .catch(() => {
            next('/login')
        })
}

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
