import { Api } from '@/core/api.js'
const api = new Api()
import Vue from 'vue'

const state = {
    notifications: {}
}

const getters = {
    getNotifications: (state) => (id) => {
        if (id) {
            return state.notifications[id]
        }
        return state.notifications
    }
}

const actions = {
    loadNotifications (context) {
        return api
            .get(`notification/list`)
            .then((response) => {
                if (response.status) {
                    context.commit('setNotifications', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    saveChanges (context, params) {
        for (var not in params) {
            var idx = params[not]
            if (idx.status === false) {
                idx.status = 0
            } else if (idx.status === true) {
                idx.status = 1
            }
        }

        return api
            .post(`notification/update`, { notifications: JSON.stringify(params) })
            .then((response) => {
                if (response.status) {
                    context.commit('setNotifications', response.data)
                }
                return response.data
            })
            .catch((error) => {
                return error
            })
    }
}

const mutations = {
    setNotifications: (state, value) => {
        state.notifications = value
        Vue.set(state, 'notifications', value)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
