import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './i18n'
import overlay from './plugins/overlay'

import DownloadLayout from './layouts/Download-Layout.vue'
import AdminLayout from './layouts/Admin-Layout.vue'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import moment from 'moment'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify'

import snackbar from './plugins/snackbar'

Vue.use(i18n)
Vue.use(snackbar)
Vue.use(overlay)

const vuetify = new Vuetify({
    theme: { disable: true },
    icons: {
        iconfont: 'md'
    }
})

Vue.use(Vuetify)

Vue.component('download-layout', DownloadLayout)
Vue.component('admin-layout', AdminLayout)

Vue.config.productionTip = false
Vue.prototype.moment = moment

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App)
}).$mount('#app')
