<template>
	<div id="left-menu">
		<div class="row" :class="{ active: selected == 'ValidTickets' }" @click="changeTool('ValidTickets')">
			<div class="icon bills"></div>
			<div class="label">{{ $t('tools.valid_tickets') }}</div>
		</div>
		<div class="row" :class="{ active: selected == 'WrongTickets' }" @click="changeTool('WrongTickets')">
			<div class="icon clients"></div>
			<div class="label">{{ $t('tools.wrong_tickets') }}</div>
		</div>
		<div class="row" :class="{ active: selected == 'Access' || selected == 'AddAccess' || selected == 'EditAccess' }" @click="changeTool('Access')" v-if="![3, 4].includes(userType)">
			<div class="icon access"></div>
			<div class="label">{{ $t('tools.users') }}</div>
		</div>
		<div class="row" :class="{ active: selected == 'Notifications' }" @click="changeTool('Notifications')">
			<div class="icon notifications"></div>
			<div class="label">{{ $t('tools.notifications') }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LeftMenu',
	computed: {
		selected() {
			return this.$route.name
		},
		userType() {
			const { type } = this.$store.getters['getUserLogged']
			return type
		}
	},
	methods: {
		changeTool(page) {
			this.$router.push({ name: page }).catch(() => {})
		}
	},
	created() {}
}
</script>

<style lang="scss">
#left-menu {
	height: 100%;
	width: 250px;
	padding-top: 30px;
	padding-left: 15px;

	.row {
		width: 100%;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 40px;
		cursor: pointer;

		.icon {
			background-size: 90%;
			background-position: center center;
			background-repeat: no-repeat;
			width: 24px;
			height: 24px;

			&.bills {
				background-image: url('../../../public/img/bills.svg');
			}

			&.clients {
				background-image: url('../../../public/img/clients.svg');
			}

			&.restaurants {
				background-image: url('../../../public/img/restaurants.svg');
			}

			&.access {
				background-image: url('../../../public/img/access.svg');
			}

			&.notifications {
				background-image: url('../../../public/img/notifications.svg');
			}
		}

		.label {
			color: #222;
			font-family: Speedee-lt;
			font-size: 18px;
			margin-left: 20px;
		}

		&.active,
		&:hover {
			border-right: 6px solid #ffbc0d;

			.icon {
				&.bills {
					background-image: url('../../../public/img/billsSelected.svg');
				}

				&.clients {
					background-image: url('../../../public/img/clientsSelected.svg');
				}

				&.restaurants {
					background-image: url('../../../public/img/restaurantsSelected.svg');
				}

				&.access {
					background-image: url('../../../public/img/accessSelected.svg');
				}

				&.notifications {
					background-image: url('../../../public/img/notificationsSelected.svg');
				}
			}

			.label {
				font-family: Speedee-bd;
			}
		}
	}
}
</style>
