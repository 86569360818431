import { Api } from '@/core/api.js'
const api = new Api()
import Vue from 'vue'

const state = {
    users: {},
    newAccess: {
        name: '',
        email: '',
        restaurants: []
    }
}

const getters = {
    getAccess: (state) => (id) => {
        if (id) {
            return state.users[id]
        }
        return state.users
    }
}

const actions = {
    loadAccess (context) {
        return api
            .get(`user/list`)
            .then((response) => {
                if (response.status) {
                    context.commit('setAccess', response.data)
                }
                return response
            })
            .catch((error) => {
                return error
            })
    },
    changePassword (context, params) {
        return api
            .post(`user/changePassword`, params)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error
            })
    },
    editAccess (context, params) {
        var user = context.getters['getAccess'](params)
        user.username = user.email
        user.add = 0
        return api.post('user/edit', user).then(function (response) {
            if (response.status) {
                // response.data.locations = Object.values(response.data.locations)
                context.commit('updateAccess', response.data)
            } else {
                // console.log(response.status);
            }
        })
    },
    addAccess (context, params) {
        var user = context.getters['getAccess'](params)
        delete user['id']
        user.username = user.email
        user.add = 1
        return api.post('user/add', user).then(function (response) {
            if (response.status) {
                // response.data.locations = Object.values(response.data.locations)
                context.commit('updateAccess', response.data)
            } else {
                // console.log(response.status);
            }
        })
    },
    addNewAccess (context) {
        var users = Object.assign({}, context.state.newAccess)
        users.id = 'tmpitw'
        context.commit('createNewAccess', users)
        return users.id
    },
    validateUser (context, params) {
        var userToValidate = context.getters['getAccess'](params)
        var errors = {
            name: false,
            email: false,
            restaurants: false
        }
        const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var regexp = new RegExp('(@es.mcd.com)')

        var sendForm = true
        Object.keys(errors).forEach((v) => (errors[v] = false))

        if (userToValidate.name === '') {
            sendForm = false
            errors.name = 'users.error_empty_name'
        }

        if (!emailValidation.test(userToValidate.email.toLowerCase())) {
            sendForm = false
            errors.email = 'users.error_no_valid_email'
        }

        if (userToValidate.email === '') {
            sendForm = false
            errors.email = 'users.error_empty_email'
        }

        if (!errors.email && !regexp.exec(userToValidate.email)) {
            sendForm = false
            errors.email = 'users.error_email_mcd'
        }

        return {
            status: sendForm,
            errors: errors
        }
    },
    removeUser (context, params) {
        return api.post(`user/delete`, params).then((response) => {
            if (response.status) {
                context.commit('setRemoveUser', params)
            }
            return response
        })
    }
}

const mutations = {
    setAccess: (state, value) => {
        state.users = value
        Vue.set(state, 'users', value)
    },
    setRemoveUser: (state, value) => {
        var users = { ...state.users }
        delete users[value]
        state.users = users
        Vue.set(state, 'users', users)
    },
    updateAccess (state, payload) {
        var users = { ...state.users }
        users[payload.id] = payload
        Vue.set(state, 'users', { ...users })
    },
    createNewAccess (state, payload) {
        var users = { ...state.users }
        users[payload.id] = payload
        Vue.set(state, 'users', { ...users })
    },
    deleteUser (state, params) {
        var users = state.users
        delete users[params]
        state.users = users
        Vue.set(state, 'users', { ...users })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
